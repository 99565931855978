<template>
  <v-container fluid  class="wrapper d-flex align-center" v-if="!$auth.check()">
      <v-row class="centered">
        <v-col>
          <v-row class="centered">
            <!-- TODO: logo -->
            <!-- <logo-header></logo-header> -->
          </v-row>
          <v-row>
            <v-layout class="centered">
              <v-card width="400px">
                <v-card-title primary-title class="subheading text--darken-2">
                  Log in to
                  <v-spacer>
                  </v-spacer>
                  <!-- <router-link :to="{name: 'register'}"> -->
                    <!-- <v-btn disabled outlined class="success--text">Sign up</v-btn> -->
                  <!-- </router-link> -->
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-layout>
                    <p>
                      Sign in with social providers:
                    </p>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <github></github>
                    </v-flex>
                    <v-flex>
                      <google></google>
                    </v-flex>
                  </v-layout>
                </v-card-text>

                <v-divider class="mt-2 mb-4"></v-divider>
                <v-card-text>
                  <v-layout>
                    <p>
                      Or with regular credentials:
                    </p>
                  </v-layout>
                  <form v-on:submit.prevent="login()">
                    <v-text-field id="email" label="email" v-model="data.body.email">
                    </v-text-field>
                    <v-text-field id="password" label="password" type="password" v-model="data.body.password">
                    </v-text-field>
                    <v-text-field name="_gotcha" style="display:none" type="text" v-model="data.body._gotcha"></v-text-field>
                    <v-layout>
                      <v-alert
                          v-if="error"
                          dense
                          outlined
                          type="error"
                        >
                          {{ error }}
                        </v-alert>
                    </v-layout>
                    <v-layout>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-btn tag="button" block outlined class="primary--text" v-bind:disabled="buttonSubmitLoading" v-bind:loading="buttonSubmitLoading" type="submit">
                          Login
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <v-layout class="mt-2">
                      <v-flex xs12 sm12 md12 lg12>
                      </v-flex>
                    </v-layout>
                  </form>
                </v-card-text>
              </v-card>
          </v-layout>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<style lang="stylus">
  .oauth {
    img {
      height: 25px;
    }
  }

  .centered {
    text-align: center;
    margin: auto;
    display: flex;
    // margin-top: 6rem
    justify-content: center;
  }
  .wrapper {
    height: 100%;
  }
  .login {
    // margin: 50%;
    top: auto;
    right: 20px;
    // bottom: 30px;
    left: auto;
    position: fixed;
    z-index: 1;
  }
</style>

<script>
import Github from './Github';
import Google from './Google';

export default {
  components: {
    Github,
    Google
  },
  data () {
    return {

      buttonSubmitLoading: false,

      data: {
        body: {
          email: '',
          password: '',
          _gotcha: ''
        },
        rememberMe: false
      },

      error: null,
      emailNotVerified: false
    }
  },

  mounted () {
    if (this.$auth.check()) {
      location.href = '/'
    }
  },

  methods: {
    async login () {
      this.buttonSubmitLoading = true
      this.$auth.login({
        data: this.data.body,
        rememberMe: this.data.rememberMe,
        redirect: { name: 'home' },
        fetchUser: false,
        })
        .then(() => {
          this.buttonSubmitLoading = false

          }, (res) => {
              this.buttonSubmitLoading = false
              this.error = res.response.data.error.message
          })
    }
  }
}
</script>
